<template>
  <div class="menus-archive-list-page">
    <v-menus-list :menus="menus" :loading="isMenusLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VMenusList from "../../components/menus/VMenusList";

export default {
  name: "MenusArchiveList",

  layout: "default",

  components: { VMenusList },

  metaInfo() {
    return { title: this.$t("Menu.ArchivedMenus") };
  },

  computed: mapGetters({
    menus: "menus/archivedMenus",
    isMenusLoading: "menus/isMenusLoading",
  }),

  mounted() {
    this.$store.dispatch("menus/fetchMenus");
  },

  destroyed() {
    this.$store.dispatch("menus/clearMenus");
  },
};
</script>

<style></style>
